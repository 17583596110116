import { executeFrameRequest } from '@/unlayer-tools/requests/frame-request';

export function requestBrandLogos({ useCache = false } = {}) {
    return executeFrameRequest('getBrandLogos', { useCache }, '*', 'parent');
}

export function requestBrandLogoDependencies({ useCache = false } = {}) {
    return executeFrameRequest('getBrandLogoDependencies', { useCache }, '*', 'parent');
}

export function requestBrandColors() {
    return executeFrameRequest('getBrandColors', null, '*', 'parent');
}

export function requestBrandingCenter() {
    return executeFrameRequest('showBrandingServiceCenter', null, '*', 'parent');
}

export function createLogoDependency(requestData) {
    return executeFrameRequest('createLogoDependency', requestData, '*', 'parent');
}

export function deleteLogoDependency(requestData) {
    return executeFrameRequest('deleteLogoDependency', requestData, '*', 'parent');
}
