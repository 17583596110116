import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import style from '../../editors/styles/FormEditorStyle.scss';
import { Button } from 'reactstrap';

const WarningAlertMessage = ({ alertMessage }) => {
    const [isVisible, setIsVisible] = useState(true);

    return (
        <>
            {isVisible && (
                <div className={style.virtualFieldAlert} color="secondary">
                    <div className={style.alertContainer}>
                        <div className={style.alertIcon}>
                            <FontAwesomeIcon icon={faExclamationCircle} />
                        </div>
                        <div className={style.labelStyle}>
                            {alertMessage}
                        </div>
                    </div>
                    {<Button onClick={() => setIsVisible(false)} className={style.dismissButton}>
                        Dismiss
                    </Button>}
                </div>
            )}
        </>
    );
};

export default WarningAlertMessage;
